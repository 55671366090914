var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.form_loading,
          expression: "form_loading"
        }
      ],
      staticClass: "forgot_password-container"
    },
    [
      _c("div", { staticClass: "forgot_password-box" }, [
        _vm.action_step == "FORGOT_PASSWORD_INPUT"
          ? _c(
              "div",
              { staticClass: "forgot_password-container-input" },
              [
                _c("div", { staticClass: "logo-container" }, [
                  _c("img", {
                    attrs: { src: _vm.publicPath + "img/logo-black.png" }
                  })
                ]),
                _c("div", { staticClass: "page-title" }, [
                  _c("h3", [_vm._v("Reset Password")]),
                  _c(
                    "p",
                    {
                      style: {
                        color:
                          _vm.feedback_message.type == "error"
                            ? "#e81123"
                            : "inherit"
                      }
                    },
                    [_vm._v(_vm._s(_vm.feedback_message.message))]
                  )
                ]),
                _c("el-input", {
                  attrs: { autofocus: "", placeholder: "Email" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submit($event)
                    }
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                }),
                _c(
                  "p",
                  [
                    _vm._v("No account? "),
                    _c("router-link", { attrs: { to: "register" } }, [
                      _vm._v("Create one!")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action-button" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "secondary-color",
                        attrs: { type: "primary" },
                        on: { click: _vm.submit }
                      },
                      [_vm._v("Send link")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.action_step == "FORGOT_PASSWORD_WAITING"
          ? _c("div", { staticClass: "forgot_password-container-waiting" }, [
              _c("div", { staticClass: "start-icon-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "start-icon",
                    staticStyle: { padding: "30px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/email-check.png",
                        alt: ""
                      }
                    })
                  ]
                )
              ]),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "24px",
                    "text-align": "center",
                    margin: "20px 0 10px 0"
                  }
                },
                [_vm._v("Check your email!")]
              ),
              _c(
                "span",
                { staticStyle: { display: "block", "text-align": "center" } },
                [
                  _vm._v("We have sent you an email to "),
                  _c("b", [_vm._v(_vm._s(this.email))]),
                  _vm._v(
                    ". Check your email and click on the confirmation link to continue."
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm.action_step == "FORGOT_PASSWORD_RESET"
          ? _c(
              "div",
              { staticClass: "forgot_password-container-password" },
              [
                _c("div", { staticClass: "logo-container" }, [
                  _c("img", {
                    attrs: { src: _vm.publicPath + "img/logo-black.png" }
                  })
                ]),
                _c(
                  "form-item",
                  { attrs: { label: "New password" } },
                  [
                    _c("el-input", {
                      attrs: {
                        autofocus: "",
                        placeholder: "Your new password",
                        type: "password"
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "form-item",
                  { attrs: { label: "Confirm password" } },
                  [
                    _c("el-input", {
                      attrs: {
                        autofocus: "",
                        placeholder: "Confirm your new password",
                        type: "password"
                      },
                      model: {
                        value: _vm.confirm_password,
                        callback: function($$v) {
                          _vm.confirm_password = $$v
                        },
                        expression: "confirm_password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "action-button" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "secondary-color",
                        attrs: { type: "primary" },
                        on: { click: _vm.submit_password_reset }
                      },
                      [_vm._v("Change password")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
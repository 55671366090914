<template>
  <div class="form-item">
      <span class="label">{{ label }}</span>
      <slot></slot>
  </div>
</template>

<script>
export default {
    props: {
        label: { type: String, default: '' }
    }
}
</script>

<style lang="scss" scoped>
  .form-item {
    margin-bottom: 5px;
    
		.label {
			display: block;
			margin-bottom: 3px;
		}
	}
</style>
<template>
  <div v-loading="form_loading" class="forgot_password-container">
    <div class="forgot_password-box">
      <!-- Show only when asking for email -->
      <div v-if="action_step == 'FORGOT_PASSWORD_INPUT'" class="forgot_password-container-input">
        <div class="logo-container">
          <img :src="`${publicPath}img/logo-black.png`">
        </div>

        <div class="page-title">
          <h3>Reset Password</h3>
          <p :style="{ color: feedback_message.type == 'error' ? '#e81123' : 'inherit' }">{{ feedback_message.message }}</p>
        </div>

        <el-input autofocus @keyup.native.enter="submit" placeholder="Email" v-model="email"></el-input>

        <p>No account? <router-link to="register">Create one!</router-link></p>

        <div class="action-button">
          <el-button
            class="secondary-color"
            type="primary"
            @click="submit"
          >Send link</el-button>
        </div>
      </div>

      <!-- Show only if waiting for email link -->
      <div v-if="action_step == 'FORGOT_PASSWORD_WAITING'" class="forgot_password-container-waiting">
        <div class="start-icon-container">
					<div class="start-icon" style="padding: 30px">
						<img :src="`${publicPath}img/email-check.png`" alt="">
					</div>
				</div>

        <span style="display: block; font-size: 24px; text-align: center; margin: 20px 0 10px 0;">Check your email!</span>
				<span style="display: block; text-align: center">We have sent you an email to <b>{{ this.email }}</b>. Check your email and click on the confirmation link to continue.</span>
      </div>

      <!-- Show only if applying new password -->
      <div v-if="action_step == 'FORGOT_PASSWORD_RESET'" class="forgot_password-container-password">
        <div class="logo-container">
          <img :src="`${publicPath}img/logo-black.png`">
        </div>

        <form-item label="New password">
          <el-input autofocus placeholder="Your new password" type="password" v-model="password"></el-input>
        </form-item>

        <form-item label="Confirm password">
          <el-input autofocus placeholder="Confirm your new password" type="password" v-model="confirm_password"></el-input>
        </form-item>

        <div class="action-button">
          <el-button
            class="secondary-color"
            type="primary"
            @click="submit_password_reset"
          >Change password</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FormItem from '@/components/Form/FormItem';
import firebase from '@/firebase';

export default {
  components: { FormItem },
  data: () => ({
    email: '',
    password: '',
    confirm_password: '',
    oobCode: '',
    feedback_message: {
      type: 'info',
      message: "You need to use your organization's email.",
    },
    action_step: 'FORGOT_PASSWORD_INPUT', /* 'FORGOT_PASSWORD_INPUT', 'FORGOT_PASSWORD_WAITING', 'FORGOT_PASSWORD_RESET' */
    form_loading: false,
    publicPath: process.env.BASE_URL
  }),
  computed: {},

  methods: {
    submit() {
      this.email = this.email.trim();
      this.setFeedback('info', "You need to use your organization's email.");

      if (!this.validateEmail(this.email)) {
        return this.setFeedback('error', "Email is not valid.");
      }

      this.form_loading = true;


      this.SharoAPI({ action: "userAuthHelper", data: { email: this.email, type: "generatePasswordResetLink", url: window.location.origin }}).then(result => {
        if (result.data.error) {
          return this.setFeedback("error", result.data.error_message);
        }

        this.action_step = "FORGOT_PASSWORD_WAITING";

      }).finally(_ => this.form_loading = false);
    },

    submit_password_reset() {
      if (this.password !== this.confirm_password) {
        return this.$message({ type: 'error', showClose: true, message: 'Your passwords do not match' });
      }

      if (this.password.length < 6) {
        return this.$message({ type: 'error', showClose: true, message: 'Your password should be at least 6 characters' });
      }

      firebase.auth().confirmPasswordReset(this.oobCode, this.password).then(result => {
        this.$message({ type: 'success', showClose: true, message: 'Password successfully changed.' });
        this.$router.push('login');
      }).catch(error => {
        console.error(error);
      });
    },

    setFeedback(type, message) {
      this.feedback_message.type = type;
      this.feedback_message.message = message;
    }
  },

  async mounted() {
    this.email = this.$route.query.email || '';

    if (this.$route.query.action == 'FORGOT_PASSWORD_RESET') {
      if (!this.$route.query.oobCode) {
        return this.setFeedback('error', "There's been an error with your link, try again or contact admins. ERR_NO_OOBCODE");
      }

      // Verifify oobCode
      firebase.auth().verifyPasswordResetCode(this.$route.query.oobCode).then(email => {
        this.email = email;
        this.oobCode = this.$route.query.oobCode;
        this.action_step = 'FORGOT_PASSWORD_RESET';
      }).catch(err => {
        return this.setFeedback('error', "There's been an error with your link, try again or contact admins. ERR_INVALID_OOBCODE");
      });
    }
  }
}
</script>





<style lang="scss" scoped>
@import '@/theme/variables.scss';

.logo-container {
  width: 100%;
  opacity: 0.8;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img { width: 120px; }
}

.start-icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;

  .start-icon {
    width: 150px;
    height: 150px;
    padding: 20px;
    // border: 1px solid blue;
    background-color: #d1f1ff;
    border-radius: 50%;

    img {
      width: 100%;
      opacity: 0.9;
    }
  }
}

.forgot_password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 69px);
}

.forgot_password-box {
  padding: 15px;
  max-width: 500px;
  // border: 1px solid blue;
  width: 100%;
}

.el-input {
  margin-bottom: 15px;
}

.action-button {
  width: 100%;
  text-align: right;
}
</style>
